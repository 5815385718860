import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApolloClient, gql, NormalizedCacheObject } from "@apollo/client";
import { RootState } from "@src/redux/store";
import * as TYPES from "@src/redux/types";

const GRAPHQL = gql`
    query get_previous_estimated_demand_list($pagination:pagination_input!,$filter:estimated_demand_filter_input!,$sort:[sort_input]!,) {
      get_previous_estimated_demand_list(pagination:$pagination,filter:$filter,sort:$sort,) {
        items
{
product
{
category_id
}
quantity
delivery_date_id
product_variant_id
product_id
id
}
pagination
{
total_count
page_limit
page_number
}
error
{
status_code
message
}

      }
  }
`;

const initial_state: TYPES.EstimatedDemandListState = {
  result:null,
  error: null,
  loading: false,
};

export const get_previous_estimated_demand_list_request = createAsyncThunk<
  TYPES.EstimatedDemandList,
  { pagination:TYPES.PaginationInput;filter:TYPES.EstimatedDemandFilterInput;sort:TYPES.SortInput; },
  { extra: ApolloClient<NormalizedCacheObject> }
>("estimated_demand/get_previous_estimated_demand_list", async (params: {  pagination:TYPES.PaginationInput;filter:TYPES.EstimatedDemandFilterInput;sort:TYPES.SortInput; }, { extra: client }) => {
  try {
    const response = await client.query({
      query: GRAPHQL,
      variables: params,
    });
    return response.data.get_previous_estimated_demand_list;
  } catch (error) {
    const err = error as { message?: String; status_code?: String };
    return Promise.reject({
      message: err.message || "An error occurred",
      status_code: err.status_code || "500",
    });
  }
});

const get_previous_estimated_demand_list_slice = createSlice({
  name: "get_previous_estimated_demand_list",
  initialState: initial_state,
  reducers: {
      get_previous_estimated_demand_list_clear: (state) => {
        state.result = null;
        state.error = null;
        state.loading = false;
      },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_previous_estimated_demand_list_request.pending, (state) => {
        state.result = null;
        state.error = null;
        state.loading = true;
      })
      .addCase(get_previous_estimated_demand_list_request.fulfilled, (state, action) => {
        state.result = action.payload;
        state.loading = false;
      })
      .addCase(get_previous_estimated_demand_list_request.rejected, (state, action) => {
        state.error = (action.payload as Error) || {
          message: "Failed to fetch",
          status_code: "500",
        };
        state.loading = false;
      });
  },
});

export const { get_previous_estimated_demand_list_clear } = get_previous_estimated_demand_list_slice.actions;

export const get_previous_estimated_demand_list = (state: RootState) => ({
  result: state.estimated_demand.get_previous_estimated_demand_list.result,
  error: state.estimated_demand.get_previous_estimated_demand_list.error,
  loading: state.estimated_demand.get_previous_estimated_demand_list.loading,
});

export default get_previous_estimated_demand_list_slice.reducer;
