import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApolloClient, gql, NormalizedCacheObject } from "@apollo/client";
import { RootState } from "@src/redux/store";
import * as TYPES from "@src/redux/types";

const GRAPHQL = gql`
  query get_previous_demand_list(
    $pagination: pagination_input!
    $sort: sort_input!
    $filter: farmer_order_filter_input!
  ) {
    get_previous_demand_list(
      pagination: $pagination
      sort: $sort
      filter: $filter
    ) {
      items {
        id
        farmer_id
        ordered_datetime
        farmer {
          id
          name
        }
        delivery_date {
          date
        }
        products: order_line_item_list {
          id
          farmer_product_id
          quantity
          farmer_product {
            product_id
            product_variant_id
          }
        }
      }
      pagination {
        page_number
        page_limit
        total_count
      }
      error {
        message
        status_code
      }
    }
  }
`;

const initial_state: TYPES.FarmerOrderListState = {
  result: null,
  error: null,
  loading: false,
};

export const get_previous_demand_list_request = createAsyncThunk<
  TYPES.FarmerOrderList,
  {
    pagination: TYPES.PaginationInput;
    sort: TYPES.SortInput;
    filter: TYPES.FarmerOrderFilterInput;
  },
  { extra: ApolloClient<NormalizedCacheObject> }
>(
  "farmer_order/get_previous_demand_list",
  async (
    params: {
      pagination: TYPES.PaginationInput;
      sort: TYPES.SortInput;
      filter: TYPES.FarmerOrderFilterInput;
    },
    { extra: client }
  ) => {
    try {
      const response = await client.query({
        query: GRAPHQL,
        variables: params,
      });
      return response.data.get_previous_demand_list;
    } catch (error) {
      const err = error as { message?: String; status_code?: String };
      return Promise.reject({
        message: err.message || "An error occurred",
        status_code: err.status_code || "500",
      });
    }
  }
);

const get_previous_demand_list_slice = createSlice({
  name: "get_previous_demand_list",
  initialState: initial_state,
  reducers: {
    get_previous_demand_list_clear: (state) => {
      state.result = null;
      state.error = null;
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_previous_demand_list_request.pending, (state) => {
        state.result = null;
        state.error = null;
        state.loading = true;
      })
      .addCase(get_previous_demand_list_request.fulfilled, (state, action) => {
        state.result = action.payload;
        state.loading = false;
      })
      .addCase(get_previous_demand_list_request.rejected, (state, action) => {
        state.error = (action.payload as Error) || {
          message: "Failed to fetch",
          status_code: "500",
        };
        state.loading = false;
      });
  },
});

export const { get_previous_demand_list_clear } =
  get_previous_demand_list_slice.actions;

export const get_previous_demand_list = (state: RootState) => ({
  result: state.farmer_order.get_previous_demand_list.result,
  error: state.farmer_order.get_previous_demand_list.error,
  loading: state.farmer_order.get_previous_demand_list.loading,
});

export default get_previous_demand_list_slice.reducer;
