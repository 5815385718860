import { combineReducers } from "@reduxjs/toolkit";
import get_customer_order_line_item_list_reducer from "./get_customer_order_line_item_list";


const customer_order_line_item_reducer = combineReducers({
get_customer_order_line_item_list:get_customer_order_line_item_list_reducer,

});

export default customer_order_line_item_reducer;
