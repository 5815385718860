import { combineReducers } from "@reduxjs/toolkit";
import create_warehouse_reducer from "./create_warehouse";
import delete_warehouse_reducer from "./delete_warehouse";
import get_warehouse_reducer from "./get_warehouse";
import get_warehouse_list_reducer from "./get_warehouse_list";
import update_warehouse_reducer from "./update_warehouse";


const warehouse_reducer = combineReducers({
create_warehouse:create_warehouse_reducer,
delete_warehouse:delete_warehouse_reducer,
get_warehouse:get_warehouse_reducer,
get_warehouse_list:get_warehouse_list_reducer,
update_warehouse:update_warehouse_reducer,

});

export default warehouse_reducer;
