import { combineReducers } from "@reduxjs/toolkit";
import get_customer_order_reducer from "./get_customer_order";
import get_customer_order_list_reducer from "./get_customer_order_list";


const customer_order_reducer = combineReducers({
get_customer_order:get_customer_order_reducer,
get_customer_order_list:get_customer_order_list_reducer,

});

export default customer_order_reducer;
