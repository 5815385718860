import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApolloClient, gql, NormalizedCacheObject } from "@apollo/client";
import { RootState } from "@src/redux/store";
import * as TYPES from "@src/redux/types";

const GRAPHQL = gql`
    query get_warehouse($filter:warehouse_filter_input,) {
      get_warehouse(filter:$filter,) {
        id
name
address
mobile
staff_id
zone_id
lat_lng
error
{
status_code
message
}

      }
    }
`;

const initial_state: TYPES.WarehouseState = {
  result: null,
  error: null,
  loading: false,
};

export const get_warehouse_request = createAsyncThunk<
  TYPES.Warehouse,
  { filter?: TYPES.WarehouseFilterInput },
  { extra: ApolloClient<NormalizedCacheObject> }
>(
  "warehouse/get_warehouse",
  async (
    params: { filter?: TYPES.WarehouseFilterInput },
    { extra: client }
  ) => {
    try {
      const response = await client.query({
        query: GRAPHQL,
        variables: params,
      });
      return response.data.get_warehouse;
    } catch (error) {
      const err = error as { message?: String; status_code?: String };
      return Promise.reject({
        message: err.message || "An error occurred",
        status_code: err.status_code || "500",
      });
    }
  }
);

const get_warehouse_slice = createSlice({
  name: "get_warehouse",
  initialState: initial_state,
  reducers: {
    get_warehouse_clear: (state) => {
      state.result = null;
      state.error = null;
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_warehouse_request.pending, (state) => {
        state.result = null;
        state.error = null;
        state.loading = true;
      })
      .addCase(get_warehouse_request.fulfilled, (state, action) => {
        state.result = action.payload;
        state.loading = false;
      })
      .addCase(get_warehouse_request.rejected, (state, action) => {
        state.error = (action.payload as Error) || {
          message: "Failed to fetch",
          status_code: "500",
        };
        state.loading = false;
      });
  },
});

export const { get_warehouse_clear } = get_warehouse_slice.actions;

export const get_warehouse = (state: RootState) => ({
  result: state.warehouse.get_warehouse.result,
  error: state.warehouse.get_warehouse.error,
  loading: state.warehouse.get_warehouse.loading,
});

export default get_warehouse_slice.reducer;
