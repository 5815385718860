import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApolloClient, gql, NormalizedCacheObject } from "@apollo/client";
import { RootState } from "@src/redux/store";
import * as TYPES from "@src/redux/types";

const GRAPHQL = gql`
  mutation create_system_otp($data: create_system_otp_input!) {
    create_system_otp(data: $data) {
      id
      status
      error {
        message
        status_code
      }
    }
  }
`;

const initial_state: TYPES.MutationResponseState = {
  result: null,
  error: null,
  loading: false,
};

export const create_system_otp_request = createAsyncThunk<
  TYPES.MutationResponse,
  { data: TYPES.CreateSystemOtpInput },
  { extra: ApolloClient<NormalizedCacheObject> }
>(
  "system_otp/create_system_otp",
  async (params: { data: TYPES.CreateSystemOtpInput }, { extra: client }) => {
    try {
      const response = await client.mutate({
        mutation: GRAPHQL,
        variables: params,
      });
      return response.data.create_system_otp;
    } catch (error) {
      const err = error as { message?: String; status_code?: String };
      return Promise.reject({
        message: err.message || "An error occurred",
        status_code: err.status_code || "500",
      });
    }
  }
);

const create_system_otp_slice = createSlice({
  name: "create_system_otp",
  initialState: initial_state,
  reducers: {
    create_system_otp_clear: (state) => {
      state.result = null;
      state.error = null;
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(create_system_otp_request.pending, (state) => {
        state.result = null;
        state.error = null;
        state.loading = true;
      })
      .addCase(create_system_otp_request.fulfilled, (state, action) => {
        state.result = action.payload;
        state.loading = false;
      })
      .addCase(create_system_otp_request.rejected, (state, action) => {
        state.error = (action.payload as Error) || {
          message: "Failed to fetch",
          status_code: "500",
        };
        state.loading = false;
      });
  },
});

export const { create_system_otp_clear } = create_system_otp_slice.actions;

export const create_system_otp = (state: RootState) => ({
  result: state.system_otp.create_system_otp.result,
  error: state.system_otp.create_system_otp.error,
  loading: state.system_otp.create_system_otp.loading,
});

export default create_system_otp_slice.reducer;
