import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApolloClient, gql, NormalizedCacheObject } from "@apollo/client";
import { RootState } from "@src/redux/store";
import * as TYPES from "@src/redux/types";

const GRAPHQL = gql`
    query get_delivery_date($filter:delivery_date_filter_input,) {
      get_delivery_date(filter:$filter,) {
        id
date
closing_time_in_mins
next_delivery_date_id
is_active
estimated_customer_count
zone_group_list
{
name
id
}
error
{
status_code
message
}

      }
  }
`;

const initial_state: TYPES.DeliveryDateState = {
  result:null,
  error: null,
  loading: false,
};

export const get_delivery_date_request = createAsyncThunk<
  TYPES.DeliveryDate,
  { filter?:TYPES.DeliveryDateFilterInput; },
  { extra: ApolloClient<NormalizedCacheObject> }
>("delivery_date/get_delivery_date", async (params: {  filter?:TYPES.DeliveryDateFilterInput; }, { extra: client }) => {
  try {
    const response = await client.query({
      query: GRAPHQL,
      variables: params,
    });
    return response.data.get_delivery_date;
  } catch (error) {
    const err = error as { message?: String; status_code?: String };
    return Promise.reject({
      message: err.message || "An error occurred",
      status_code: err.status_code || "500",
    });
  }
});

const get_delivery_date_slice = createSlice({
  name: "get_delivery_date",
  initialState: initial_state,
  reducers: {
      get_delivery_date_clear: (state) => {
        state.result = null;
        state.error = null;
        state.loading = false;
      },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_delivery_date_request.pending, (state) => {
        state.result = null;
        state.error = null;
        state.loading = true;
      })
      .addCase(get_delivery_date_request.fulfilled, (state, action) => {
        state.result = action.payload;
        state.loading = false;
      })
      .addCase(get_delivery_date_request.rejected, (state, action) => {
        state.error = (action.payload as Error) || {
          message: "Failed to fetch",
          status_code: "500",
        };
        state.loading = false;
      });
  },
});

export const { get_delivery_date_clear } = get_delivery_date_slice.actions;

export const get_delivery_date = (state: RootState) => ({
  result: state.delivery_date.get_delivery_date.result,
  error: state.delivery_date.get_delivery_date.error,
  loading: state.delivery_date.get_delivery_date.loading,
});

export default get_delivery_date_slice.reducer;
