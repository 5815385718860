import { combineReducers } from "@reduxjs/toolkit";
import create_vehicle_reducer from "./create_vehicle";
import delete_vehicle_reducer from "./delete_vehicle";
import get_vehicle_reducer from "./get_vehicle";
import get_vehicle_list_reducer from "./get_vehicle_list";
import update_vehicle_reducer from "./update_vehicle";


const vehicle_reducer = combineReducers({
create_vehicle:create_vehicle_reducer,
delete_vehicle:delete_vehicle_reducer,
get_vehicle:get_vehicle_reducer,
get_vehicle_list:get_vehicle_list_reducer,
update_vehicle:update_vehicle_reducer,

});

export default vehicle_reducer;
