import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApolloClient, gql, NormalizedCacheObject } from "@apollo/client";
import { RootState } from "@src/redux/store";
import * as TYPES from "@src/redux/types";

const GRAPHQL = gql`
    query get_system_api_module_list($filter:system_api_module_filter_input,$pagination:pagination_input,$sort:[sort_input],) {
      get_system_api_module_list(filter:$filter,pagination:$pagination,sort:$sort,) {
        items
{
id
name
}
pagination
{
page_number
page_limit
total_count
}
error
{
message
status_code
}

      }
  }
`;

const initial_state: TYPES.SystemApiModuleListState = {
  result:null,
  error: null,
  loading: false,
};

export const get_system_api_module_list_request = createAsyncThunk<
  TYPES.SystemApiModuleList,
  { filter?:TYPES.SystemApiModuleFilterInput;pagination?:TYPES.PaginationInput;sort?:TYPES.SortInput; },
  { extra: ApolloClient<NormalizedCacheObject> }
>("system_api_module/get_system_api_module_list", async (params: {  filter?:TYPES.SystemApiModuleFilterInput;pagination?:TYPES.PaginationInput;sort?:TYPES.SortInput; }, { extra: client }) => {
  try {
    const response = await client.query({
      query: GRAPHQL,
      variables: params,
    });
    return response.data.get_system_api_module_list;
  } catch (error) {
    const err = error as { message?: String; status_code?: String };
    return Promise.reject({
      message: err.message || "An error occurred",
      status_code: err.status_code || "500",
    });
  }
});

const get_system_api_module_list_slice = createSlice({
  name: "get_system_api_module_list",
  initialState: initial_state,
  reducers: {
      get_system_api_module_list_clear: (state) => {
        state.result = null;
        state.error = null;
        state.loading = false;
      },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_system_api_module_list_request.pending, (state) => {
        state.result = null;
        state.error = null;
        state.loading = true;
      })
      .addCase(get_system_api_module_list_request.fulfilled, (state, action) => {
        state.result = action.payload;
        state.loading = false;
      })
      .addCase(get_system_api_module_list_request.rejected, (state, action) => {
        state.error = (action.payload as Error) || {
          message: "Failed to fetch",
          status_code: "500",
        };
        state.loading = false;
      });
  },
});

export const { get_system_api_module_list_clear } = get_system_api_module_list_slice.actions;

export const get_system_api_module_list = (state: RootState) => ({
  result: state.system_api_module.get_system_api_module_list.result,
  error: state.system_api_module.get_system_api_module_list.error,
  loading: state.system_api_module.get_system_api_module_list.loading,
});

export default get_system_api_module_list_slice.reducer;
