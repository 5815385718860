import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApolloClient, gql, NormalizedCacheObject } from "@apollo/client";
import { RootState } from "@src/redux/store";
import * as TYPES from "@src/redux/types";

const GRAPHQL = gql`
    mutation create_staff($data:create_staff_input!,) {
      create_staff(data:$data,) {
        id
status
error
{
message
status_code
}

      }
  }
`;

const initial_state: TYPES.MutationResponseState = {
  result:null,
  error: null,
  loading: false,
};

export const create_staff_request = createAsyncThunk<
  TYPES.MutationResponse,
  { data:TYPES.CreateStaffInput; },
  { extra: ApolloClient<NormalizedCacheObject> }
>("staff/create_staff", async (params: {  data:TYPES.CreateStaffInput; }, { extra: client }) => {
  try {
    const response = await client.mutate({
      mutation: GRAPHQL,
      variables: params,
    });
    return response.data.create_staff;
  } catch (error) {
    const err = error as { message?: String; status_code?: String };
    return Promise.reject({
      message: err.message || "An error occurred",
      status_code: err.status_code || "500",
    });
  }
});

const create_staff_slice = createSlice({
  name: "create_staff",
  initialState: initial_state,
  reducers: {
      create_staff_clear: (state) => {
        state.result = null;
        state.error = null;
        state.loading = false;
      },
  },
  extraReducers: (builder) => {
    builder
      .addCase(create_staff_request.pending, (state) => {
        state.result = null;
        state.error = null;
        state.loading = true;
      })
      .addCase(create_staff_request.fulfilled, (state, action) => {
        state.result = action.payload;
        state.loading = false;
      })
      .addCase(create_staff_request.rejected, (state, action) => {
        state.error = (action.payload as Error) || {
          message: "Failed to fetch",
          status_code: "500",
        };
        state.loading = false;
      });
  },
});

export const { create_staff_clear } = create_staff_slice.actions;

export const create_staff = (state: RootState) => ({
  result: state.staff.create_staff.result,
  error: state.staff.create_staff.error,
  loading: state.staff.create_staff.loading,
});

export default create_staff_slice.reducer;
