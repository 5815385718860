import { retrieve_item } from "@src/helpers/functions";

export const useCheckPageAccess = (key: string): boolean => {
  let has_access: boolean = false;
  let page_accesses = retrieve_item("page_accesses");

  try {
    let page_access = page_accesses.find((x: string) => x === key);

    if (page_access) {
      has_access = true;
    }
  } catch (e) {
    has_access = false;
  }
  return has_access;
};
