import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApolloClient, gql, NormalizedCacheObject } from "@apollo/client";
import { RootState } from "@src/redux/store";
import * as TYPES from "@src/redux/types";

const GRAPHQL = gql`
    query get_product($filter:product_filter_input,) {
      get_product(filter:$filter,) {
        id
name
description
image_list
category_id
measurement_type
minimum_stock_quantity
total_stock_quantity
name
id
ta_name
description
hsn_code
image_list
category_id
measurement_type
category
{
name
id
}
variant_list
{
measurement_type
price
quantity
description
name
id
}
error
{
status_code
message
}

      }
  }
`;

const initial_state: TYPES.ProductState = {
  result:null,
  error: null,
  loading: false,
};

export const get_product_request = createAsyncThunk<
  TYPES.Product,
  { filter?:TYPES.ProductFilterInput; },
  { extra: ApolloClient<NormalizedCacheObject> }
>("product/get_product", async (params: {  filter?:TYPES.ProductFilterInput; }, { extra: client }) => {
  try {
    const response = await client.query({
      query: GRAPHQL,
      variables: params,
    });
    return response.data.get_product;
  } catch (error) {
    const err = error as { message?: String; status_code?: String };
    return Promise.reject({
      message: err.message || "An error occurred",
      status_code: err.status_code || "500",
    });
  }
});

const get_product_slice = createSlice({
  name: "get_product",
  initialState: initial_state,
  reducers: {
      get_product_clear: (state) => {
        state.result = null;
        state.error = null;
        state.loading = false;
      },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_product_request.pending, (state) => {
        state.result = null;
        state.error = null;
        state.loading = true;
      })
      .addCase(get_product_request.fulfilled, (state, action) => {
        state.result = action.payload;
        state.loading = false;
      })
      .addCase(get_product_request.rejected, (state, action) => {
        state.error = (action.payload as Error) || {
          message: "Failed to fetch",
          status_code: "500",
        };
        state.loading = false;
      });
  },
});

export const { get_product_clear } = get_product_slice.actions;

export const get_product = (state: RootState) => ({
  result: state.product.get_product.result,
  error: state.product.get_product.error,
  loading: state.product.get_product.loading,
});

export default get_product_slice.reducer;
