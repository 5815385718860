import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApolloClient, gql, NormalizedCacheObject } from "@apollo/client";
import { RootState } from "@src/redux/store";
import * as TYPES from "@src/redux/types";

const GRAPHQL = gql`
    query get_warehouse_list($filter:warehouse_filter_input,$pagination:pagination_input,$sort:[sort_input],) {
      get_warehouse_list(filter:$filter,pagination:$pagination,sort:$sort,) {
        items
{
id
name
mobile
zone
{
id
name
}
}
pagination
{
page_number
page_limit
total_count
}
error
{
message
status_code
}

      }
  }
`;

const initial_state: TYPES.WarehouseListState = {
  result:null,
  error: null,
  loading: false,
};

export const get_warehouse_list_request = createAsyncThunk<
  TYPES.WarehouseList,
  { filter?:TYPES.WarehouseFilterInput;pagination?:TYPES.PaginationInput;sort?:TYPES.SortInput; },
  { extra: ApolloClient<NormalizedCacheObject> }
>("warehouse/get_warehouse_list", async (params: {  filter?:TYPES.WarehouseFilterInput;pagination?:TYPES.PaginationInput;sort?:TYPES.SortInput; }, { extra: client }) => {
  try {
    const response = await client.query({
      query: GRAPHQL,
      variables: params,
    });
    return response.data.get_warehouse_list;
  } catch (error) {
    const err = error as { message?: String; status_code?: String };
    return Promise.reject({
      message: err.message || "An error occurred",
      status_code: err.status_code || "500",
    });
  }
});

const get_warehouse_list_slice = createSlice({
  name: "get_warehouse_list",
  initialState: initial_state,
  reducers: {
      get_warehouse_list_clear: (state) => {
        state.result = null;
        state.error = null;
        state.loading = false;
      },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_warehouse_list_request.pending, (state) => {
        state.result = null;
        state.error = null;
        state.loading = true;
      })
      .addCase(get_warehouse_list_request.fulfilled, (state, action) => {
        state.result = action.payload;
        state.loading = false;
      })
      .addCase(get_warehouse_list_request.rejected, (state, action) => {
        state.error = (action.payload as Error) || {
          message: "Failed to fetch",
          status_code: "500",
        };
        state.loading = false;
      });
  },
});

export const { get_warehouse_list_clear } = get_warehouse_list_slice.actions;

export const get_warehouse_list = (state: RootState) => ({
  result: state.warehouse.get_warehouse_list.result,
  error: state.warehouse.get_warehouse_list.error,
  loading: state.warehouse.get_warehouse_list.loading,
});

export default get_warehouse_list_slice.reducer;
