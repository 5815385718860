import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApolloClient, gql, NormalizedCacheObject } from "@apollo/client";
import { RootState } from "@src/redux/store";
import * as TYPES from "@src/redux/types";

const GRAPHQL = gql`
  query get_customer_order_list(
    $filter: customer_order_filter_input
    $pagination: pagination_input
    $sort: [sort_input]
  ) {
    get_customer_order_list(
      filter: $filter
      pagination: $pagination
      sort: $sort
    ) {
      items {
        customer {
          name
        }
        order_line_item_list {
          id
          customer_order {
            customer_address {
              id
              name
              mobile
              house_no
              address_line_1
              address_line_2
              landmark
              zone {
                id
                name
                pincode
              }
              district {
                id
                name
              }
              state {
                id
                name
              }
            }
          }
          product {
            id
            name
            ta_name
            image_list
          }
          product_variant {
            id
            name
            quantity
            measurement_type
          }
          actual_cost
          pick_status
          quantity
          customer_order {
            total_items
          }
        }
        customer_payment_order_list {
          id
          customer_order_id
          amount
          payment_mode
          payment_transaction_id
          received_by_driver_id
        }
        customer_order_continuous_number {
          id
          customer_order_id
        }
        total_weight
        total_items
        payment_link
        delivery_remarks
        final_total_cost
        actual_total_cost
        received_datetime
        status
        updated_datetime
        delivery_date_id
        ordered_datetime
        customer_address_id
        customer_id
        order_reference
        id
      }
      pagination {
        total_count
        page_limit
        page_number
      }
      error {
        status_code
        message
      }
    }
  }
`;

const initial_state: TYPES.CustomerOrderListState = {
  result: null,
  error: null,
  loading: false,
};

export const get_customer_order_list_request = createAsyncThunk<
  TYPES.CustomerOrderList,
  {
    filter?: TYPES.CustomerOrderFilterInput;
    pagination?: TYPES.PaginationInput;
    sort?: TYPES.SortInput;
  },
  { extra: ApolloClient<NormalizedCacheObject> }
>(
  "customer_order/get_customer_order_list",
  async (
    params: {
      filter?: TYPES.CustomerOrderFilterInput;
      pagination?: TYPES.PaginationInput;
      sort?: TYPES.SortInput;
    },
    { extra: client }
  ) => {
    try {
      const response = await client.query({
        query: GRAPHQL,
        variables: params,
      });
      return response.data.get_customer_order_list;
    } catch (error) {
      const err = error as { message?: String; status_code?: String };
      return Promise.reject({
        message: err.message || "An error occurred",
        status_code: err.status_code || "500",
      });
    }
  }
);

const get_customer_order_list_slice = createSlice({
  name: "get_customer_order_list",
  initialState: initial_state,
  reducers: {
    get_customer_order_list_clear: (state) => {
      state.result = null;
      state.error = null;
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_customer_order_list_request.pending, (state) => {
        state.result = null;
        state.error = null;
        state.loading = true;
      })
      .addCase(get_customer_order_list_request.fulfilled, (state, action) => {
        state.result = action.payload;
        state.loading = false;
      })
      .addCase(get_customer_order_list_request.rejected, (state, action) => {
        state.error = (action.payload as Error) || {
          message: "Failed to fetch",
          status_code: "500",
        };
        state.loading = false;
      });
  },
});

export const { get_customer_order_list_clear } =
  get_customer_order_list_slice.actions;

export const get_customer_order_list = (state: RootState) => ({
  result: state.customer_order.get_customer_order_list.result,
  error: state.customer_order.get_customer_order_list.error,
  loading: state.customer_order.get_customer_order_list.loading,
});

export default get_customer_order_list_slice.reducer;
