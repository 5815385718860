import { combineReducers } from "@reduxjs/toolkit";
import create_system_otp_reducer from "./create_system_otp";


const system_otp_reducer = combineReducers({
create_system_otp:create_system_otp_reducer,

});

export default system_otp_reducer;
