import { combineReducers } from "@reduxjs/toolkit";
import create_farmer_reducer from "./create_farmer";
import delete_farmer_reducer from "./delete_farmer";
import get_farmer_reducer from "./get_farmer";
import get_farmer_list_reducer from "./get_farmer_list";
import get_farmer_list_for_select__reducer from "./get_farmer_list_for_select_";
import update_farmer_reducer from "./update_farmer";


const farmer_reducer = combineReducers({
create_farmer:create_farmer_reducer,
delete_farmer:delete_farmer_reducer,
get_farmer:get_farmer_reducer,
get_farmer_list:get_farmer_list_reducer,
get_farmer_list_for_select_:get_farmer_list_for_select__reducer,
update_farmer:update_farmer_reducer,

});

export default farmer_reducer;
