import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApolloClient, gql, NormalizedCacheObject } from "@apollo/client";
import { RootState } from "@src/redux/store";
import * as TYPES from "@src/redux/types";

const GRAPHQL = gql`
    query logout {
      logout {
        id
status
error
{
message
status_code
}

      }
  }
`;

const initial_state: TYPES.MutationResponseState = {
  result:null,
  error: null,
  loading: false,
};

export const logout_request = createAsyncThunk<
  TYPES.MutationResponse,
  {  },
  { extra: ApolloClient<NormalizedCacheObject> }
>("system_user/logout", async (params: {   }, { extra: client }) => {
  try {
    const response = await client.query({
      query: GRAPHQL,
      variables: params,
    });
    return response.data.logout;
  } catch (error) {
    const err = error as { message?: String; status_code?: String };
    return Promise.reject({
      message: err.message || "An error occurred",
      status_code: err.status_code || "500",
    });
  }
});

const logout_slice = createSlice({
  name: "logout",
  initialState: initial_state,
  reducers: {
      logout_clear: (state) => {
        state.result = null;
        state.error = null;
        state.loading = false;
      },
  },
  extraReducers: (builder) => {
    builder
      .addCase(logout_request.pending, (state) => {
        state.result = null;
        state.error = null;
        state.loading = true;
      })
      .addCase(logout_request.fulfilled, (state, action) => {
        state.result = action.payload;
        state.loading = false;
      })
      .addCase(logout_request.rejected, (state, action) => {
        state.error = (action.payload as Error) || {
          message: "Failed to fetch",
          status_code: "500",
        };
        state.loading = false;
      });
  },
});

export const { logout_clear } = logout_slice.actions;

export const logout = (state: RootState) => ({
  result: state.system_user.logout.result,
  error: state.system_user.logout.error,
  loading: state.system_user.logout.loading,
});

export default logout_slice.reducer;
