import { combineReducers } from "@reduxjs/toolkit";
import change_password_reducer from "./change_password";
import login_reducer from "./login";
import logout_reducer from "./logout";
import schema_reducer from "./schema";



const system_user_reducer = combineReducers({
change_password:change_password_reducer,
login:login_reducer,
logout:logout_reducer,
schema: schema_reducer,


});

export default system_user_reducer;
