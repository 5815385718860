import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApolloClient, gql, NormalizedCacheObject } from "@apollo/client";
import { RootState } from "@src/redux/store";
import * as TYPES from "@src/redux/types";

const GRAPHQL = gql`
    mutation update_system_ui_module_component($id:String!,$data:update_system_ui_module_component_input!,) {
      update_system_ui_module_component(id:$id,data:$data,) {
        id
status
error
{
message
status_code
}

      }
  }
`;

const initial_state: TYPES.MutationResponseState = {
  result:null,
  error: null,
  loading: false,
};

export const update_system_ui_module_component_request = createAsyncThunk<
  TYPES.MutationResponse,
  { id:String;data:TYPES.UpdateSystemUiModuleComponentInput; },
  { extra: ApolloClient<NormalizedCacheObject> }
>("system_ui_module_component/update_system_ui_module_component", async (params: {  id:String;data:TYPES.UpdateSystemUiModuleComponentInput; }, { extra: client }) => {
  try {
    const response = await client.mutate({
      mutation: GRAPHQL,
      variables: params,
    });
    return response.data.update_system_ui_module_component;
  } catch (error) {
    const err = error as { message?: String; status_code?: String };
    return Promise.reject({
      message: err.message || "An error occurred",
      status_code: err.status_code || "500",
    });
  }
});

const update_system_ui_module_component_slice = createSlice({
  name: "update_system_ui_module_component",
  initialState: initial_state,
  reducers: {
      update_system_ui_module_component_clear: (state) => {
        state.result = null;
        state.error = null;
        state.loading = false;
      },
  },
  extraReducers: (builder) => {
    builder
      .addCase(update_system_ui_module_component_request.pending, (state) => {
        state.result = null;
        state.error = null;
        state.loading = true;
      })
      .addCase(update_system_ui_module_component_request.fulfilled, (state, action) => {
        state.result = action.payload;
        state.loading = false;
      })
      .addCase(update_system_ui_module_component_request.rejected, (state, action) => {
        state.error = (action.payload as Error) || {
          message: "Failed to fetch",
          status_code: "500",
        };
        state.loading = false;
      });
  },
});

export const { update_system_ui_module_component_clear } = update_system_ui_module_component_slice.actions;

export const update_system_ui_module_component = (state: RootState) => ({
  result: state.system_ui_module_component.update_system_ui_module_component.result,
  error: state.system_ui_module_component.update_system_ui_module_component.error,
  loading: state.system_ui_module_component.update_system_ui_module_component.loading,
});

export default update_system_ui_module_component_slice.reducer;
