import { combineReducers } from "@reduxjs/toolkit";
import checkout_reducer from "./checkout";


const customer_cart_reducer = combineReducers({
checkout:checkout_reducer,

});

export default customer_cart_reducer;
