import { combineReducers } from "@reduxjs/toolkit";
import create_packing_assignment_list_iterative_reducer from "./create_packing_assignment_list_iterative";
import get_packing_assignment_list_reducer from "./get_packing_assignment_list";


const packing_assignment_reducer = combineReducers({
create_packing_assignment_list_iterative:create_packing_assignment_list_iterative_reducer,
get_packing_assignment_list:get_packing_assignment_list_reducer,

});

export default packing_assignment_reducer;
