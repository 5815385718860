import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApolloClient, gql, NormalizedCacheObject } from "@apollo/client";
import { RootState } from "@src/redux/store";
import * as TYPES from "@src/redux/types";

const GRAPHQL = gql`
    mutation create_zone($data:create_zone_input!,) {
      create_zone(data:$data,) {
        id
status
error
{
status_code
message
}

      }
  }
`;

const initial_state: TYPES.MutationResponseState = {
  result:null,
  error: null,
  loading: false,
};

export const create_zone_request = createAsyncThunk<
  TYPES.MutationResponse,
  { data:TYPES.CreateZoneInput; },
  { extra: ApolloClient<NormalizedCacheObject> }
>("zone/create_zone", async (params: {  data:TYPES.CreateZoneInput; }, { extra: client }) => {
  try {
    const response = await client.mutate({
      mutation: GRAPHQL,
      variables: params,
    });
    return response.data.create_zone;
  } catch (error) {
    const err = error as { message?: String; status_code?: String };
    return Promise.reject({
      message: err.message || "An error occurred",
      status_code: err.status_code || "500",
    });
  }
});

const create_zone_slice = createSlice({
  name: "create_zone",
  initialState: initial_state,
  reducers: {
      create_zone_clear: (state) => {
        state.result = null;
        state.error = null;
        state.loading = false;
      },
  },
  extraReducers: (builder) => {
    builder
      .addCase(create_zone_request.pending, (state) => {
        state.result = null;
        state.error = null;
        state.loading = true;
      })
      .addCase(create_zone_request.fulfilled, (state, action) => {
        state.result = action.payload;
        state.loading = false;
      })
      .addCase(create_zone_request.rejected, (state, action) => {
        state.error = (action.payload as Error) || {
          message: "Failed to fetch",
          status_code: "500",
        };
        state.loading = false;
      });
  },
});

export const { create_zone_clear } = create_zone_slice.actions;

export const create_zone = (state: RootState) => ({
  result: state.zone.create_zone.result,
  error: state.zone.create_zone.error,
  loading: state.zone.create_zone.loading,
});

export default create_zone_slice.reducer;
