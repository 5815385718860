import { combineReducers } from "@reduxjs/toolkit";
import create_product_reducer from "./create_product";
import delete_product_reducer from "./delete_product";
import get_product_reducer from "./get_product";
import get_product_list_reducer from "./get_product_list";
import update_product_reducer from "./update_product";


const product_reducer = combineReducers({
create_product:create_product_reducer,
delete_product:delete_product_reducer,
get_product:get_product_reducer,
get_product_list:get_product_list_reducer,
update_product:update_product_reducer,

});

export default product_reducer;
