import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApolloClient, gql, NormalizedCacheObject } from "@apollo/client";
import { RootState } from "@src/redux/store";
import * as TYPES from "@src/redux/types";

const GRAPHQL = gql`
    query get_category($filter:category_filter_input,) {
      get_category(filter:$filter,) {
        id
name
image
type
product_list
{
error
{
message
status_code
}
description
name
id
}
error
{
status_code
message
}

      }
  }
`;

const initial_state: TYPES.CategoryState = {
  result:null,
  error: null,
  loading: false,
};

export const get_category_request = createAsyncThunk<
  TYPES.Category,
  { filter?:TYPES.CategoryFilterInput; },
  { extra: ApolloClient<NormalizedCacheObject> }
>("category/get_category", async (params: {  filter?:TYPES.CategoryFilterInput; }, { extra: client }) => {
  try {
    const response = await client.query({
      query: GRAPHQL,
      variables: params,
    });
    return response.data.get_category;
  } catch (error) {
    const err = error as { message?: String; status_code?: String };
    return Promise.reject({
      message: err.message || "An error occurred",
      status_code: err.status_code || "500",
    });
  }
});

const get_category_slice = createSlice({
  name: "get_category",
  initialState: initial_state,
  reducers: {
      get_category_clear: (state) => {
        state.result = null;
        state.error = null;
        state.loading = false;
      },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_category_request.pending, (state) => {
        state.result = null;
        state.error = null;
        state.loading = true;
      })
      .addCase(get_category_request.fulfilled, (state, action) => {
        state.result = action.payload;
        state.loading = false;
      })
      .addCase(get_category_request.rejected, (state, action) => {
        state.error = (action.payload as Error) || {
          message: "Failed to fetch",
          status_code: "500",
        };
        state.loading = false;
      });
  },
});

export const { get_category_clear } = get_category_slice.actions;

export const get_category = (state: RootState) => ({
  result: state.category.get_category.result,
  error: state.category.get_category.error,
  loading: state.category.get_category.loading,
});

export default get_category_slice.reducer;
