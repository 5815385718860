import { combineReducers } from "@reduxjs/toolkit";
import get_product_stock_reducer from "./get_product_stock";
import get_product_stock_list_reducer from "./get_product_stock_list";
import update_product_stock_reducer from "./update_product_stock";
import update_product_stock_list_reducer from "./update_product_stock_list";


const product_stock_reducer = combineReducers({
get_product_stock:get_product_stock_reducer,
get_product_stock_list:get_product_stock_list_reducer,
update_product_stock:update_product_stock_reducer,
update_product_stock_list:update_product_stock_list_reducer,

});

export default product_stock_reducer;
