import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApolloClient, gql, NormalizedCacheObject } from "@apollo/client";
import { RootState } from "@src/redux/store";
import * as TYPES from "@src/redux/types";

const GRAPHQL = gql`
    mutation create_delivery_date($data:create_delivery_date_input!,) {
      create_delivery_date(data:$data,) {
        id
status
error
{
status_code
message
}

      }
  }
`;

const initial_state: TYPES.MutationResponseState = {
  result:null,
  error: null,
  loading: false,
};

export const create_delivery_date_request = createAsyncThunk<
  TYPES.MutationResponse,
  { data:TYPES.CreateDeliveryDateInput; },
  { extra: ApolloClient<NormalizedCacheObject> }
>("delivery_date/create_delivery_date", async (params: {  data:TYPES.CreateDeliveryDateInput; }, { extra: client }) => {
  try {
    const response = await client.mutate({
      mutation: GRAPHQL,
      variables: params,
    });
    return response.data.create_delivery_date;
  } catch (error) {
    const err = error as { message?: String; status_code?: String };
    return Promise.reject({
      message: err.message || "An error occurred",
      status_code: err.status_code || "500",
    });
  }
});

const create_delivery_date_slice = createSlice({
  name: "create_delivery_date",
  initialState: initial_state,
  reducers: {
      create_delivery_date_clear: (state) => {
        state.result = null;
        state.error = null;
        state.loading = false;
      },
  },
  extraReducers: (builder) => {
    builder
      .addCase(create_delivery_date_request.pending, (state) => {
        state.result = null;
        state.error = null;
        state.loading = true;
      })
      .addCase(create_delivery_date_request.fulfilled, (state, action) => {
        state.result = action.payload;
        state.loading = false;
      })
      .addCase(create_delivery_date_request.rejected, (state, action) => {
        state.error = (action.payload as Error) || {
          message: "Failed to fetch",
          status_code: "500",
        };
        state.loading = false;
      });
  },
});

export const { create_delivery_date_clear } = create_delivery_date_slice.actions;

export const create_delivery_date = (state: RootState) => ({
  result: state.delivery_date.create_delivery_date.result,
  error: state.delivery_date.create_delivery_date.error,
  loading: state.delivery_date.create_delivery_date.loading,
});

export default create_delivery_date_slice.reducer;
