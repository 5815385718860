import { combineReducers } from "@reduxjs/toolkit";
import create_customer_wishlist_reducer from "./create_customer_wishlist";
import delete_customer_wishlist_reducer from "./delete_customer_wishlist";
import get_customer_wishlist_list_reducer from "./get_customer_wishlist_list";
import update_customer_wishlist_reducer from "./update_customer_wishlist";


const customer_wishlist_reducer = combineReducers({
create_customer_wishlist:create_customer_wishlist_reducer,
delete_customer_wishlist:delete_customer_wishlist_reducer,
get_customer_wishlist_list:get_customer_wishlist_list_reducer,
update_customer_wishlist:update_customer_wishlist_reducer,

});

export default customer_wishlist_reducer;
