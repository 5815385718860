import { combineReducers } from "@reduxjs/toolkit";
import { dynamic_reducer } from "./slices/dynamic";
import category_reducer from "./slices/category/reducer";
import cluster_reducer from "./slices/cluster/reducer";
import customer_reducer from "./slices/customer/reducer";
import customer_address_reducer from "./slices/customer_address/reducer";
import customer_cart_reducer from "./slices/customer_cart/reducer";
import customer_cart_line_item_reducer from "./slices/customer_cart_line_item/reducer";
import customer_order_reducer from "./slices/customer_order/reducer";
import customer_order_line_item_reducer from "./slices/customer_order_line_item/reducer";
import customer_wishlist_reducer from "./slices/customer_wishlist/reducer";
import delivery_date_reducer from "./slices/delivery_date/reducer";
import delivery_date_product_stock_reducer from "./slices/delivery_date_product_stock/reducer";
import district_reducer from "./slices/district/reducer";
import estimated_demand_reducer from "./slices/estimated_demand/reducer";
import farmer_reducer from "./slices/farmer/reducer";
import farmer_order_reducer from "./slices/farmer_order/reducer";
import farmer_order_line_item_reducer from "./slices/farmer_order_line_item/reducer";
import farmer_product_reducer from "./slices/farmer_product/reducer";
import farmer_review_reducer from "./slices/farmer_review/reducer";
import packing_assignment_reducer from "./slices/packing_assignment/reducer";
import piece_conversion_reducer from "./slices/piece_conversion/reducer";
import product_reducer from "./slices/product/reducer";
import product_stock_reducer from "./slices/product_stock/reducer";
import product_variant_reducer from "./slices/product_variant/reducer";
import staff_reducer from "./slices/staff/reducer";
import state_reducer from "./slices/state/reducer";
import system_api_module_reducer from "./slices/system_api_module/reducer";
import system_file_reducer from "./slices/system_file/reducer";
import system_otp_reducer from "./slices/system_otp/reducer";
import system_role_reducer from "./slices/system_role/reducer";
import system_ui_module_reducer from "./slices/system_ui_module/reducer";
import system_ui_module_component_reducer from "./slices/system_ui_module_component/reducer";
import system_user_reducer from "./slices/system_user/reducer";
import vehicle_reducer from "./slices/vehicle/reducer";
import warehouse_reducer from "./slices/warehouse/reducer";
import zone_reducer from "./slices/zone/reducer";
import zone_group_reducer from "./slices/zone_group/reducer";


const reducer = combineReducers({
  dynamic: dynamic_reducer,
category:category_reducer,
cluster:cluster_reducer,
customer:customer_reducer,
customer_address:customer_address_reducer,
customer_cart:customer_cart_reducer,
customer_cart_line_item:customer_cart_line_item_reducer,
customer_order:customer_order_reducer,
customer_order_line_item:customer_order_line_item_reducer,
customer_wishlist:customer_wishlist_reducer,
delivery_date:delivery_date_reducer,
delivery_date_product_stock:delivery_date_product_stock_reducer,
district:district_reducer,
estimated_demand:estimated_demand_reducer,
farmer:farmer_reducer,
farmer_order:farmer_order_reducer,
farmer_order_line_item:farmer_order_line_item_reducer,
farmer_product:farmer_product_reducer,
farmer_review:farmer_review_reducer,
packing_assignment:packing_assignment_reducer,
piece_conversion:piece_conversion_reducer,
product:product_reducer,
product_stock:product_stock_reducer,
product_variant:product_variant_reducer,
staff:staff_reducer,
state:state_reducer,
system_api_module:system_api_module_reducer,
system_file:system_file_reducer,
system_otp:system_otp_reducer,
system_role:system_role_reducer,
system_ui_module:system_ui_module_reducer,
system_ui_module_component:system_ui_module_component_reducer,
system_user:system_user_reducer,
vehicle:vehicle_reducer,
warehouse:warehouse_reducer,
zone:zone_reducer,
zone_group:zone_group_reducer,

});

export default reducer;