import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApolloClient, gql, NormalizedCacheObject } from "@apollo/client";
import { RootState } from "@src/redux/store";
import * as TYPES from "@src/redux/types";

const GRAPHQL = gql`
    query get_zone_group($filter:zone_group_filter_input,) {
      get_zone_group(filter:$filter,) {
        id
name
zone_list
{
id
}
delivery_date_list
{
id
}
error
{
message
status_code
}

      }
  }
`;

const initial_state: TYPES.ZoneGroupState = {
  result:null,
  error: null,
  loading: false,
};

export const get_zone_group_request = createAsyncThunk<
  TYPES.ZoneGroup,
  { filter?:TYPES.ZoneGroupFilterInput; },
  { extra: ApolloClient<NormalizedCacheObject> }
>("zone_group/get_zone_group", async (params: {  filter?:TYPES.ZoneGroupFilterInput; }, { extra: client }) => {
  try {
    const response = await client.query({
      query: GRAPHQL,
      variables: params,
    });
    return response.data.get_zone_group;
  } catch (error) {
    const err = error as { message?: String; status_code?: String };
    return Promise.reject({
      message: err.message || "An error occurred",
      status_code: err.status_code || "500",
    });
  }
});

const get_zone_group_slice = createSlice({
  name: "get_zone_group",
  initialState: initial_state,
  reducers: {
      get_zone_group_clear: (state) => {
        state.result = null;
        state.error = null;
        state.loading = false;
      },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_zone_group_request.pending, (state) => {
        state.result = null;
        state.error = null;
        state.loading = true;
      })
      .addCase(get_zone_group_request.fulfilled, (state, action) => {
        state.result = action.payload;
        state.loading = false;
      })
      .addCase(get_zone_group_request.rejected, (state, action) => {
        state.error = (action.payload as Error) || {
          message: "Failed to fetch",
          status_code: "500",
        };
        state.loading = false;
      });
  },
});

export const { get_zone_group_clear } = get_zone_group_slice.actions;

export const get_zone_group = (state: RootState) => ({
  result: state.zone_group.get_zone_group.result,
  error: state.zone_group.get_zone_group.error,
  loading: state.zone_group.get_zone_group.loading,
});

export default get_zone_group_slice.reducer;
