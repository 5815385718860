import { combineReducers } from "@reduxjs/toolkit";
import create_system_ui_module_reducer from "./create_system_ui_module";
import delete_system_ui_module_reducer from "./delete_system_ui_module";
import get_system_ui_module_list_reducer from "./get_system_ui_module_list";
import update_system_ui_module_reducer from "./update_system_ui_module";
import update_system_ui_module_list_reducer from "./update_system_ui_module_list";


const system_ui_module_reducer = combineReducers({
create_system_ui_module:create_system_ui_module_reducer,
delete_system_ui_module:delete_system_ui_module_reducer,
get_system_ui_module_list:get_system_ui_module_list_reducer,
update_system_ui_module:update_system_ui_module_reducer,
update_system_ui_module_list:update_system_ui_module_list_reducer,

});

export default system_ui_module_reducer;
