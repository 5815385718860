import { combineReducers } from "@reduxjs/toolkit";
import get_delivery_date_product_stock_list_reducer from "./get_delivery_date_product_stock_list";


const delivery_date_product_stock_reducer = combineReducers({
get_delivery_date_product_stock_list:get_delivery_date_product_stock_list_reducer,

});

export default delivery_date_product_stock_reducer;
