import { combineReducers } from "@reduxjs/toolkit";
import get_signed_url_reducer from "./get_signed_url";


const system_file_reducer = combineReducers({
get_signed_url:get_signed_url_reducer,

});

export default system_file_reducer;
