import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApolloClient, gql, NormalizedCacheObject } from "@apollo/client";
import { RootState } from "@src/redux/store";
import * as TYPES from "@src/redux/types";

const GRAPHQL = gql`
    mutation update_farmer_order_line_item_list($filter:farmer_order_line_item_filter_input!,$data:update_farmer_order_line_item_input!,) {
      update_farmer_order_line_item_list(filter:$filter,data:$data,) {
        id
status
error
{
message
status_code
}

      }
  }
`;

const initial_state: TYPES.MutationResponseState = {
  result:null,
  error: null,
  loading: false,
};

export const update_farmer_order_line_item_list_request = createAsyncThunk<
  TYPES.MutationResponse,
  { filter:TYPES.FarmerOrderLineItemFilterInput;data:TYPES.UpdateFarmerOrderLineItemInput; },
  { extra: ApolloClient<NormalizedCacheObject> }
>("farmer_order_line_item/update_farmer_order_line_item_list", async (params: {  filter:TYPES.FarmerOrderLineItemFilterInput;data:TYPES.UpdateFarmerOrderLineItemInput; }, { extra: client }) => {
  try {
    const response = await client.mutate({
      mutation: GRAPHQL,
      variables: params,
    });
    return response.data.update_farmer_order_line_item_list;
  } catch (error) {
    const err = error as { message?: String; status_code?: String };
    return Promise.reject({
      message: err.message || "An error occurred",
      status_code: err.status_code || "500",
    });
  }
});

const update_farmer_order_line_item_list_slice = createSlice({
  name: "update_farmer_order_line_item_list",
  initialState: initial_state,
  reducers: {
      update_farmer_order_line_item_list_clear: (state) => {
        state.result = null;
        state.error = null;
        state.loading = false;
      },
  },
  extraReducers: (builder) => {
    builder
      .addCase(update_farmer_order_line_item_list_request.pending, (state) => {
        state.result = null;
        state.error = null;
        state.loading = true;
      })
      .addCase(update_farmer_order_line_item_list_request.fulfilled, (state, action) => {
        state.result = action.payload;
        state.loading = false;
      })
      .addCase(update_farmer_order_line_item_list_request.rejected, (state, action) => {
        state.error = (action.payload as Error) || {
          message: "Failed to fetch",
          status_code: "500",
        };
        state.loading = false;
      });
  },
});

export const { update_farmer_order_line_item_list_clear } = update_farmer_order_line_item_list_slice.actions;

export const update_farmer_order_line_item_list = (state: RootState) => ({
  result: state.farmer_order_line_item.update_farmer_order_line_item_list.result,
  error: state.farmer_order_line_item.update_farmer_order_line_item_list.error,
  loading: state.farmer_order_line_item.update_farmer_order_line_item_list.loading,
});

export default update_farmer_order_line_item_list_slice.reducer;
