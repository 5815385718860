import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApolloClient, gql, NormalizedCacheObject } from "@apollo/client";
import { RootState } from "@src/redux/store";
import * as TYPES from "@src/redux/types";

const GRAPHQL = gql`
    query get_delivery_date_list_for_select($filter:delivery_date_filter_input,$pagination:pagination_input,$sort:[sort_input],) {
      get_delivery_date_list(filter:$filter,pagination:$pagination,sort:$sort,) {
        error
{
status_code
message
}
items
{
date
id
}
pagination
{
page_limit
page_number
total_count
}

      }
  }
`;

const initial_state: TYPES.DeliveryDateListState = {
  result:null,
  error: null,
  loading: false,
};

export const get_delivery_date_list_for_select_request = createAsyncThunk<
  TYPES.DeliveryDateList,
  { filter?:TYPES.DeliveryDateFilterInput;pagination?:TYPES.PaginationInput;sort?:TYPES.SortInput; },
  { extra: ApolloClient<NormalizedCacheObject> }
>("delivery_date/get_delivery_date_list_for_select", async (params: {  filter?:TYPES.DeliveryDateFilterInput;pagination?:TYPES.PaginationInput;sort?:TYPES.SortInput; }, { extra: client }) => {
  try {
    const response = await client.query({
      query: GRAPHQL,
      variables: params,
    });
    return response.data.get_delivery_date_list;
  } catch (error) {
    const err = error as { message?: String; status_code?: String };
    return Promise.reject({
      message: err.message || "An error occurred",
      status_code: err.status_code || "500",
    });
  }
});

const get_delivery_date_list_for_select_slice = createSlice({
  name: "get_delivery_date_list_for_select",
  initialState: initial_state,
  reducers: {
      get_delivery_date_list_for_select_clear: (state) => {
        state.result = null;
        state.error = null;
        state.loading = false;
      },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_delivery_date_list_for_select_request.pending, (state) => {
        state.result = null;
        state.error = null;
        state.loading = true;
      })
      .addCase(get_delivery_date_list_for_select_request.fulfilled, (state, action) => {
        state.result = action.payload;
        state.loading = false;
      })
      .addCase(get_delivery_date_list_for_select_request.rejected, (state, action) => {
        state.error = (action.payload as Error) || {
          message: "Failed to fetch",
          status_code: "500",
        };
        state.loading = false;
      });
  },
});

export const { get_delivery_date_list_for_select_clear } = get_delivery_date_list_for_select_slice.actions;

export const get_delivery_date_list_for_select = (state: RootState) => ({
  result: state.delivery_date.get_delivery_date_list_for_select.result,
  error: state.delivery_date.get_delivery_date_list_for_select.error,
  loading: state.delivery_date.get_delivery_date_list_for_select.loading,
});

export default get_delivery_date_list_for_select_slice.reducer;
