import { combineReducers } from "@reduxjs/toolkit";
import get_district_list_reducer from "./get_district_list";


const district_reducer = combineReducers({
get_district_list:get_district_list_reducer,

});

export default district_reducer;
