import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApolloClient, gql, NormalizedCacheObject } from "@apollo/client";
import { RootState } from "@src/redux/store";
import * as TYPES from "@src/redux/types";

const GRAPHQL = gql`
    mutation update_farmer_product($id:String!,$data:update_farmer_product_input!,) {
      update_farmer_product(id:$id,data:$data,) {
        id
status
error
{
status_code
message
}

      }
  }
`;

const initial_state: TYPES.MutationResponseState = {
  result:null,
  error: null,
  loading: false,
};

export const update_farmer_product_request = createAsyncThunk<
  TYPES.MutationResponse,
  { id:String;data:TYPES.UpdateFarmerProductInput; },
  { extra: ApolloClient<NormalizedCacheObject> }
>("farmer_product/update_farmer_product", async (params: {  id:String;data:TYPES.UpdateFarmerProductInput; }, { extra: client }) => {
  try {
    const response = await client.mutate({
      mutation: GRAPHQL,
      variables: params,
    });
    return response.data.update_farmer_product;
  } catch (error) {
    const err = error as { message?: String; status_code?: String };
    return Promise.reject({
      message: err.message || "An error occurred",
      status_code: err.status_code || "500",
    });
  }
});

const update_farmer_product_slice = createSlice({
  name: "update_farmer_product",
  initialState: initial_state,
  reducers: {
      update_farmer_product_clear: (state) => {
        state.result = null;
        state.error = null;
        state.loading = false;
      },
  },
  extraReducers: (builder) => {
    builder
      .addCase(update_farmer_product_request.pending, (state) => {
        state.result = null;
        state.error = null;
        state.loading = true;
      })
      .addCase(update_farmer_product_request.fulfilled, (state, action) => {
        state.result = action.payload;
        state.loading = false;
      })
      .addCase(update_farmer_product_request.rejected, (state, action) => {
        state.error = (action.payload as Error) || {
          message: "Failed to fetch",
          status_code: "500",
        };
        state.loading = false;
      });
  },
});

export const { update_farmer_product_clear } = update_farmer_product_slice.actions;

export const update_farmer_product = (state: RootState) => ({
  result: state.farmer_product.update_farmer_product.result,
  error: state.farmer_product.update_farmer_product.error,
  loading: state.farmer_product.update_farmer_product.loading,
});

export default update_farmer_product_slice.reducer;
