import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApolloClient, gql, NormalizedCacheObject } from "@apollo/client";
import { RootState } from "@src/redux/store";
import * as TYPES from "@src/redux/types";

const GRAPHQL = gql`
    mutation delete_system_ui_module($id:String!,) {
      delete_system_ui_module(id:$id,) {
        id
status
error
{
message
status_code
}

      }
  }
`;

const initial_state: TYPES.MutationResponseState = {
  result:null,
  error: null,
  loading: false,
};

export const delete_system_ui_module_request = createAsyncThunk<
  TYPES.MutationResponse,
  { id:String; },
  { extra: ApolloClient<NormalizedCacheObject> }
>("system_ui_module/delete_system_ui_module", async (params: {  id:String; }, { extra: client }) => {
  try {
    const response = await client.mutate({
      mutation: GRAPHQL,
      variables: params,
    });
    return response.data.delete_system_ui_module;
  } catch (error) {
    const err = error as { message?: String; status_code?: String };
    return Promise.reject({
      message: err.message || "An error occurred",
      status_code: err.status_code || "500",
    });
  }
});

const delete_system_ui_module_slice = createSlice({
  name: "delete_system_ui_module",
  initialState: initial_state,
  reducers: {
      delete_system_ui_module_clear: (state) => {
        state.result = null;
        state.error = null;
        state.loading = false;
      },
  },
  extraReducers: (builder) => {
    builder
      .addCase(delete_system_ui_module_request.pending, (state) => {
        state.result = null;
        state.error = null;
        state.loading = true;
      })
      .addCase(delete_system_ui_module_request.fulfilled, (state, action) => {
        state.result = action.payload;
        state.loading = false;
      })
      .addCase(delete_system_ui_module_request.rejected, (state, action) => {
        state.error = (action.payload as Error) || {
          message: "Failed to fetch",
          status_code: "500",
        };
        state.loading = false;
      });
  },
});

export const { delete_system_ui_module_clear } = delete_system_ui_module_slice.actions;

export const delete_system_ui_module = (state: RootState) => ({
  result: state.system_ui_module.delete_system_ui_module.result,
  error: state.system_ui_module.delete_system_ui_module.error,
  loading: state.system_ui_module.delete_system_ui_module.loading,
});

export default delete_system_ui_module_slice.reducer;
