import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApolloClient, gql, NormalizedCacheObject } from "@apollo/client";
import { RootState } from "@src/redux/store";
import * as TYPES from "@src/redux/types";

const GRAPHQL = gql`
    query get_product_variant($filter:product_variant_filter_input,) {
      get_product_variant(filter:$filter,) {
        id
name
description
quantity
product_id
price
max_price
min_price
measurement_type

      }
  }
`;

const initial_state: TYPES.ProductVariantState = {
  result:null,
  error: null,
  loading: false,
};

export const get_product_variant_request = createAsyncThunk<
  TYPES.ProductVariant,
  { filter?:TYPES.ProductVariantFilterInput; },
  { extra: ApolloClient<NormalizedCacheObject> }
>("product_variant/get_product_variant", async (params: {  filter?:TYPES.ProductVariantFilterInput; }, { extra: client }) => {
  try {
    const response = await client.query({
      query: GRAPHQL,
      variables: params,
    });
    return response.data.get_product_variant;
  } catch (error) {
    const err = error as { message?: String; status_code?: String };
    return Promise.reject({
      message: err.message || "An error occurred",
      status_code: err.status_code || "500",
    });
  }
});

const get_product_variant_slice = createSlice({
  name: "get_product_variant",
  initialState: initial_state,
  reducers: {
      get_product_variant_clear: (state) => {
        state.result = null;
        state.error = null;
        state.loading = false;
      },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_product_variant_request.pending, (state) => {
        state.result = null;
        state.error = null;
        state.loading = true;
      })
      .addCase(get_product_variant_request.fulfilled, (state, action) => {
        state.result = action.payload;
        state.loading = false;
      })
      .addCase(get_product_variant_request.rejected, (state, action) => {
        state.error = (action.payload as Error) || {
          message: "Failed to fetch",
          status_code: "500",
        };
        state.loading = false;
      });
  },
});

export const { get_product_variant_clear } = get_product_variant_slice.actions;

export const get_product_variant = (state: RootState) => ({
  result: state.product_variant.get_product_variant.result,
  error: state.product_variant.get_product_variant.error,
  loading: state.product_variant.get_product_variant.loading,
});

export default get_product_variant_slice.reducer;
