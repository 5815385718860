import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApolloClient, gql, NormalizedCacheObject } from "@apollo/client";
import { RootState } from "@src/redux/store";
import * as TYPES from "@src/redux/types";

const GRAPHQL = gql`
    query get_customer_list($filter:customer_filter_input,$pagination:pagination_input,$sort:[sort_input],) {
      get_customer_list(filter:$filter,pagination:$pagination,sort:$sort,) {
        items
{
customer_address_list
{
id
is_default
zone_id
district_id
state_id
address_line_1
address_line_2
zone
{
id
name
}
}
customer_review_list
{
id
rating
}
gender
mobile
name
system_user_id
id
}
pagination
{
total_count
page_limit
page_number
}
error
{
status_code
message
}

      }
  }
`;

const initial_state: TYPES.CustomerListState = {
  result:null,
  error: null,
  loading: false,
};

export const get_customer_list_request = createAsyncThunk<
  TYPES.CustomerList,
  { filter?:TYPES.CustomerFilterInput;pagination?:TYPES.PaginationInput;sort?:TYPES.SortInput; },
  { extra: ApolloClient<NormalizedCacheObject> }
>("customer/get_customer_list", async (params: {  filter?:TYPES.CustomerFilterInput;pagination?:TYPES.PaginationInput;sort?:TYPES.SortInput; }, { extra: client }) => {
  try {
    const response = await client.query({
      query: GRAPHQL,
      variables: params,
    });
    return response.data.get_customer_list;
  } catch (error) {
    const err = error as { message?: String; status_code?: String };
    return Promise.reject({
      message: err.message || "An error occurred",
      status_code: err.status_code || "500",
    });
  }
});

const get_customer_list_slice = createSlice({
  name: "get_customer_list",
  initialState: initial_state,
  reducers: {
      get_customer_list_clear: (state) => {
        state.result = null;
        state.error = null;
        state.loading = false;
      },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_customer_list_request.pending, (state) => {
        state.result = null;
        state.error = null;
        state.loading = true;
      })
      .addCase(get_customer_list_request.fulfilled, (state, action) => {
        state.result = action.payload;
        state.loading = false;
      })
      .addCase(get_customer_list_request.rejected, (state, action) => {
        state.error = (action.payload as Error) || {
          message: "Failed to fetch",
          status_code: "500",
        };
        state.loading = false;
      });
  },
});

export const { get_customer_list_clear } = get_customer_list_slice.actions;

export const get_customer_list = (state: RootState) => ({
  result: state.customer.get_customer_list.result,
  error: state.customer.get_customer_list.error,
  loading: state.customer.get_customer_list.loading,
});

export default get_customer_list_slice.reducer;
