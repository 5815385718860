import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApolloClient, gql, NormalizedCacheObject } from "@apollo/client";
import { RootState } from "@src/redux/store";
import * as TYPES from "@src/redux/types";

const GRAPHQL = gql`
  query get_staff($filter: staff_filter_input) {
    get_staff(filter: $filter) {
      id
      name
      mobile
      system_user {
        role_list {
          name
        }
      }
      email
      vehicle_list {
        id
      }
      error {
        message
        status_code
      }
    }
  }
`;

const initial_state: TYPES.StaffState = {
  result: null,
  error: null,
  loading: false,
};

export const get_staff_request = createAsyncThunk<
  TYPES.Staff,
  { filter?: TYPES.StaffFilterInput },
  { extra: ApolloClient<NormalizedCacheObject> }
>(
  "staff/get_staff",
  async (params: { filter?: TYPES.StaffFilterInput }, { extra: client }) => {
    try {
      const response = await client.query({
        query: GRAPHQL,
        variables: params,
      });
      return response.data.get_staff;
    } catch (error) {
      const err = error as { message?: String; status_code?: String };
      return Promise.reject({
        message: err.message || "An error occurred",
        status_code: err.status_code || "500",
      });
    }
  }
);

const get_staff_slice = createSlice({
  name: "get_staff",
  initialState: initial_state,
  reducers: {
    get_staff_clear: (state) => {
      state.result = null;
      state.error = null;
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_staff_request.pending, (state) => {
        state.result = null;
        state.error = null;
        state.loading = true;
      })
      .addCase(get_staff_request.fulfilled, (state, action) => {
        state.result = action.payload;
        state.loading = false;
      })
      .addCase(get_staff_request.rejected, (state, action) => {
        state.error = (action.payload as Error) || {
          message: "Failed to fetch",
          status_code: "500",
        };
        state.loading = false;
      });
  },
});

export const { get_staff_clear } = get_staff_slice.actions;

export const get_staff = (state: RootState) => ({
  result: state.staff.get_staff.result,
  error: state.staff.get_staff.error,
  loading: state.staff.get_staff.loading,
});

export default get_staff_slice.reducer;
