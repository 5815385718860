import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApolloClient, gql, NormalizedCacheObject } from "@apollo/client";
import { RootState } from "@src/redux/store";
import * as TYPES from "@src/redux/types";

const GRAPHQL = gql`
    query get_farmer_order_line_item_list($filter:farmer_order_line_item_filter_input,$pagination:pagination_input,$sort:[sort_input],) {
      get_farmer_order_line_item_list(filter:$filter,pagination:$pagination,sort:$sort,) {
        items
{
product_variant
{
id
name
quantity
measurement_type
}
farmer_product
{
id
product_id
price
product
{
id
name
hsn_code
image_list
measurement_type
}
}
ordered_quantity
final_cost
actual_cost
grading_status
status
billing_quantity
wastage_quantity
received_quantity
additional_quantity
quantity
farmer_product_id
product_variant_id
product_id
farmer_order_id
id
}
pagination
{
total_count
page_limit
page_number
}
error
{
status_code
message
}

      }
  }
`;

const initial_state: TYPES.FarmerOrderLineItemListState = {
  result:null,
  error: null,
  loading: false,
};

export const get_farmer_order_line_item_list_request = createAsyncThunk<
  TYPES.FarmerOrderLineItemList,
  { filter?:TYPES.FarmerOrderLineItemFilterInput;pagination?:TYPES.PaginationInput;sort?:TYPES.SortInput; },
  { extra: ApolloClient<NormalizedCacheObject> }
>("farmer_order_line_item/get_farmer_order_line_item_list", async (params: {  filter?:TYPES.FarmerOrderLineItemFilterInput;pagination?:TYPES.PaginationInput;sort?:TYPES.SortInput; }, { extra: client }) => {
  try {
    const response = await client.query({
      query: GRAPHQL,
      variables: params,
    });
    return response.data.get_farmer_order_line_item_list;
  } catch (error) {
    const err = error as { message?: String; status_code?: String };
    return Promise.reject({
      message: err.message || "An error occurred",
      status_code: err.status_code || "500",
    });
  }
});

const get_farmer_order_line_item_list_slice = createSlice({
  name: "get_farmer_order_line_item_list",
  initialState: initial_state,
  reducers: {
      get_farmer_order_line_item_list_clear: (state) => {
        state.result = null;
        state.error = null;
        state.loading = false;
      },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_farmer_order_line_item_list_request.pending, (state) => {
        state.result = null;
        state.error = null;
        state.loading = true;
      })
      .addCase(get_farmer_order_line_item_list_request.fulfilled, (state, action) => {
        state.result = action.payload;
        state.loading = false;
      })
      .addCase(get_farmer_order_line_item_list_request.rejected, (state, action) => {
        state.error = (action.payload as Error) || {
          message: "Failed to fetch",
          status_code: "500",
        };
        state.loading = false;
      });
  },
});

export const { get_farmer_order_line_item_list_clear } = get_farmer_order_line_item_list_slice.actions;

export const get_farmer_order_line_item_list = (state: RootState) => ({
  result: state.farmer_order_line_item.get_farmer_order_line_item_list.result,
  error: state.farmer_order_line_item.get_farmer_order_line_item_list.error,
  loading: state.farmer_order_line_item.get_farmer_order_line_item_list.loading,
});

export default get_farmer_order_line_item_list_slice.reducer;
