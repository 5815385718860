import { combineReducers } from "@reduxjs/toolkit";
import create_zone_reducer from "./create_zone";
import delete_zone_reducer from "./delete_zone";
import get_zone_reducer from "./get_zone";
import get_zone_list_reducer from "./get_zone_list";
import update_zone_reducer from "./update_zone";


const zone_reducer = combineReducers({
create_zone:create_zone_reducer,
delete_zone:delete_zone_reducer,
get_zone:get_zone_reducer,
get_zone_list:get_zone_list_reducer,
update_zone:update_zone_reducer,

});

export default zone_reducer;
