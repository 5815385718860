import { combineReducers } from "@reduxjs/toolkit";
import create_staff_reducer from "./create_staff";
import delete_staff_reducer from "./delete_staff";
import get_staff_reducer from "./get_staff";
import get_staff_list_reducer from "./get_staff_list";
import update_staff_reducer from "./update_staff";


const staff_reducer = combineReducers({
create_staff:create_staff_reducer,
delete_staff:delete_staff_reducer,
get_staff:get_staff_reducer,
get_staff_list:get_staff_list_reducer,
update_staff:update_staff_reducer,

});

export default staff_reducer;
