import { combineReducers } from "@reduxjs/toolkit";
import create_farmer_product_reducer from "./create_farmer_product";
import delete_farmer_product_reducer from "./delete_farmer_product";
import get_farmer_product_reducer from "./get_farmer_product";
import get_farmer_product_list_reducer from "./get_farmer_product_list";
import update_farmer_product_reducer from "./update_farmer_product";


const farmer_product_reducer = combineReducers({
create_farmer_product:create_farmer_product_reducer,
delete_farmer_product:delete_farmer_product_reducer,
get_farmer_product:get_farmer_product_reducer,
get_farmer_product_list:get_farmer_product_list_reducer,
update_farmer_product:update_farmer_product_reducer,

});

export default farmer_product_reducer;
