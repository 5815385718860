import moment, { Moment } from "moment";
export const PROJECT_NAME = "myHarvest";
// export const BASE_URL = "http://192.168.1.53:6226/graphql";
// export const BASE_URL = "http://192.168.1.50:6226/graphql";
// export const BASE_URL = "http://192.168.1.53:6226/graphql";
export const BASE_URL = "https://myharvest.vertace.org/api/";

export const disable_date = (current: Moment | null): boolean => {
  return !!(current && current < moment().startOf("day"));
};

export const measurement_type: Record<string, string> = {
  piece: "pcs",
  bunch: "bch",
  gram: "g",
  kilogram: "kg",
  litre: "l",
  millilitre: "ml",
};

export const delivery_date_format = (
  date: string | Date | undefined
): string => {
  if (!date) return "";
  return moment(new Date(date)).format("DD MMM, dddd");
};

export const format_date = (date: string | Date | undefined): string => {
  if (!date) return "";
  return moment(new Date(date)).format("DD MMM, YYYY");
};

export const delivery_date_disable_condition = (
  current: any,
  validDates: String[] | undefined
): boolean => {
  const today = moment().startOf("day");
  const formatted_date = moment(new Date(current)).format("YYYY-MM-DD");
  return (
    moment(new Date(current)).isBefore(today) ||
    !validDates?.includes(formatted_date)
  );
};

export function indian_currency_format(
  amount: number = 0,
  show_zero: boolean = true
): string {
  let amountStr = amount.toFixed(2);
  let [integerPart, fractionalPart] = amountStr.split(".");

  let lastThreeDigits = integerPart.slice(-3);
  let otherDigits = integerPart.slice(0, -3);
  if (otherDigits) {
    lastThreeDigits = "," + lastThreeDigits;
  }
  otherDigits = otherDigits.replace(/\B(?=(\d{2})+(?!\d))/g, ",");
  let formattedInt = otherDigits + lastThreeDigits;

  if (show_zero) {
    return (
      "₹" +
      formattedInt +
      (fractionalPart ? "." + fractionalPart : show_zero ? ".00" : "")
    );
  } else {
    return "₹" + formattedInt;
  }
}

export function count_format(amount: number = 0): string {
  let amountStr = amount.toFixed(2);
  let [integerPart, fractionalPart] = amountStr.split(".");

  let lastThreeDigits = integerPart.slice(-3);
  let otherDigits = integerPart.slice(0, -3);
  if (otherDigits) {
    lastThreeDigits = "," + lastThreeDigits;
  }
  otherDigits = otherDigits.replace(/\B(?=(\d{2})+(?!\d))/g, ",");
  let formattedInt = otherDigits + lastThreeDigits;

  return fractionalPart ? formattedInt + "." + fractionalPart : formattedInt;
}

export const product_variant_unit: Record<string, string> = {
  piece: "pcs",
  bunch: "bch",
  gram: "g",
  kilogram: "kg",
  litre: "l",
  millilitre: "ml",
};

export const pickup_building_address: Record<string, string> = {
  Independent: "independent",
  Apartment: "apartment",
};

export const logistics_staff_role: Record<string, string> = {
  PackingTeam: "packing team",
  Driver: "driver",
};
export var pluralize = require("pluralize");

export const farmer_order_line_item_status = {
  warehouse_update: [
    {
      label: "Received",
      value: "received",
    },
    {
      label: "Partially Received",
      value: "partially_received",
    },
    {
      label: "Not Received",
      value: "not_received",
    },
    {
      label: "Extra Received",
      value: "extra_received",
    },
  ],
  procurement_update: [
    {
      label: "Paid",
      value: "paid",
    },
    {
      label: "Unpaid",
      value: "unpaid",
    },
    {
      label: "Cancelled by Firm",
      value: "cancelled_by_firm",
    },
    {
      label: "Cancelled by Farmer",
      value: "cancelled_by_farmer",
    },
  ],
  warehouse_approve: [
    {
      label: "Received",
      value: "received",
    },
    {
      label: "Partially Received",
      value: "partially_received",
    },
    {
      label: "Extra Received",
      value: "extra_received",
    },
  ],

  warehouse_reject: [
    {
      label: "Not Received",
      value: "not_received",
    },
  ],
  procurement_reject: [
    {
      label: "Cancelled by Firm",
      value: "cancelled_by_firm",
    },
    {
      label: "Cancelled by Farmer",
      value: "cancelled_by_farmer",
    },
  ],
  procurement_approve: [
    {
      label: "Paid",
      value: "paid",
    },
    {
      label: "Unpaid",
      value: "unpaid",
    },
  ],
};
