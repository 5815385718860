import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApolloClient, gql, NormalizedCacheObject } from "@apollo/client";
import { RootState } from "@src/redux/store";
import * as TYPES from "@src/redux/types";

const GRAPHQL = gql`
    query get_product_stock($filter:product_stock_filter_input,) {
      get_product_stock(filter:$filter,) {
        id
product_id
quantity
available_quantity_for_delivery_date
error
{
message
status_code
}

      }
  }
`;

const initial_state: TYPES.ProductStockState = {
  result:null,
  error: null,
  loading: false,
};

export const get_product_stock_request = createAsyncThunk<
  TYPES.ProductStock,
  { filter?:TYPES.ProductStockFilterInput; },
  { extra: ApolloClient<NormalizedCacheObject> }
>("product_stock/get_product_stock", async (params: {  filter?:TYPES.ProductStockFilterInput; }, { extra: client }) => {
  try {
    const response = await client.query({
      query: GRAPHQL,
      variables: params,
    });
    return response.data.get_product_stock;
  } catch (error) {
    const err = error as { message?: String; status_code?: String };
    return Promise.reject({
      message: err.message || "An error occurred",
      status_code: err.status_code || "500",
    });
  }
});

const get_product_stock_slice = createSlice({
  name: "get_product_stock",
  initialState: initial_state,
  reducers: {
      get_product_stock_clear: (state) => {
        state.result = null;
        state.error = null;
        state.loading = false;
      },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_product_stock_request.pending, (state) => {
        state.result = null;
        state.error = null;
        state.loading = true;
      })
      .addCase(get_product_stock_request.fulfilled, (state, action) => {
        state.result = action.payload;
        state.loading = false;
      })
      .addCase(get_product_stock_request.rejected, (state, action) => {
        state.error = (action.payload as Error) || {
          message: "Failed to fetch",
          status_code: "500",
        };
        state.loading = false;
      });
  },
});

export const { get_product_stock_clear } = get_product_stock_slice.actions;

export const get_product_stock = (state: RootState) => ({
  result: state.product_stock.get_product_stock.result,
  error: state.product_stock.get_product_stock.error,
  loading: state.product_stock.get_product_stock.loading,
});

export default get_product_stock_slice.reducer;
