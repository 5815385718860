import { combineReducers } from "@reduxjs/toolkit";
import create_cluster_reducer from "./create_cluster";


const cluster_reducer = combineReducers({
create_cluster:create_cluster_reducer,

});

export default cluster_reducer;
