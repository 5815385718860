import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApolloClient, gql, NormalizedCacheObject } from "@apollo/client";
import { RootState } from "@src/redux/store";
import * as TYPES from "@src/redux/types";

const GRAPHQL = gql`
    mutation update_staff($id:String!,$data:update_staff_input!,) {
      update_staff(id:$id,data:$data,) {
        id
status
error
{
message
status_code
}

      }
  }
`;

const initial_state: TYPES.MutationResponseState = {
  result:null,
  error: null,
  loading: false,
};

export const update_staff_request = createAsyncThunk<
  TYPES.MutationResponse,
  { id:String;data:TYPES.UpdateStaffInput; },
  { extra: ApolloClient<NormalizedCacheObject> }
>("staff/update_staff", async (params: {  id:String;data:TYPES.UpdateStaffInput; }, { extra: client }) => {
  try {
    const response = await client.mutate({
      mutation: GRAPHQL,
      variables: params,
    });
    return response.data.update_staff;
  } catch (error) {
    const err = error as { message?: String; status_code?: String };
    return Promise.reject({
      message: err.message || "An error occurred",
      status_code: err.status_code || "500",
    });
  }
});

const update_staff_slice = createSlice({
  name: "update_staff",
  initialState: initial_state,
  reducers: {
      update_staff_clear: (state) => {
        state.result = null;
        state.error = null;
        state.loading = false;
      },
  },
  extraReducers: (builder) => {
    builder
      .addCase(update_staff_request.pending, (state) => {
        state.result = null;
        state.error = null;
        state.loading = true;
      })
      .addCase(update_staff_request.fulfilled, (state, action) => {
        state.result = action.payload;
        state.loading = false;
      })
      .addCase(update_staff_request.rejected, (state, action) => {
        state.error = (action.payload as Error) || {
          message: "Failed to fetch",
          status_code: "500",
        };
        state.loading = false;
      });
  },
});

export const { update_staff_clear } = update_staff_slice.actions;

export const update_staff = (state: RootState) => ({
  result: state.staff.update_staff.result,
  error: state.staff.update_staff.error,
  loading: state.staff.update_staff.loading,
});

export default update_staff_slice.reducer;
